import React from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
// import { navigate } from '@reach/router';
import { isEmpty } from 'lodash/fp';

import { useTranslation } from 'react-i18next';
import SEO from '../components/seo';
import { VerifyRegistrationForm } from '../components/Auth';
import { Header, Content } from '../components/layouts';
import logoPath from '../images/logo.png';
import { auth } from '../state';
import { PreLoader } from '../components/custom';

const ResetPassword = () => {
  const user = useSelector((state) => auth.selectors.selectUser(state));
  const { t } = useTranslation();
  React.useEffect(() => {
    if (isEmpty(user)) {
      navigate('/');
    }
  });

  if (isEmpty(user)) {
    return <PreLoader />;
  }

  return (
    <>
      <SEO title="Verify Registration" />
      <Header noMenu showHomePage={false} />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <CardMedia
            src={logoPath}
            component="img"
            alt="Pintuna logo"
            style={{ width: '200px' }}
          />
        </Box>
        <Box py={2} align="center">
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 15 }}
          >
            {t('pleaseEnterSecurityCodeFromEmail')}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 13 }}
          >
            ({t('checkYourSpamFolderIfYouHaveNotReceivedTheEmail')})
          </Typography>
        </Box>
        <VerifyRegistrationForm />
      </Content>
    </>
  );
};

export default ResetPassword;
